"use client"

import { getTheme } from "@/theme"
import { Box, Grid, SxProps, Theme, alpha } from "@mui/material"
import Image from "next/image"
import { CustomIcon } from "../icons/CustomIcon"
import { Section } from "../layout/Section"
import { List, ListItem } from "../list/List"
import Card from "../surfaces/Card"
import { Title, TitleSecondary } from "../typography/Title"
import animations from "../utility/animations"
import SlideUpAnimation from "../utility/SlideUpAnimation"

const examples = [
    {
        title: "Master Topics Faster",
        icon: "/svgs/icons/hat-icon.svg",
        content: [
            "Get 1 click summaries with the Browser Extension.",
            "Chat & take notes directly in your browser that auto sync to your knowledge base.",
            "Reinforce learning with a spaced repetition quiz.",
        ],
    },
    {
        title: "Accelerate Research",
        icon: "/svgs/icons/bulb-icon.svg",
        content: [
            "Upload documents & PDFs",
            "Expand your research with an interactive knowledge graph.",
            "Spark creativity with Augmented Browsing—surfacing past research as you explore new content.",
        ],
    },
    {
        title: "Organize Personal Interests",
        icon: "/svgs/icons/layers-icon.svg",
        content: [
            "Store your favourite movies, books & recipes.",
            "Easily find them again with automatic categorization.",
            "Create a living network of knowledge—  linking related content together.",
        ],
    },
]

export default function UsageExamples() {
    return (
        <Section>
            <SlideUpAnimation sx={styles.container}>
                <Title component="h2" maxWidth={700} hasBlueGradient mb={{ xs: 2, lg: 6 }}>
                    How Nerds Are Using Recall
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.4s">
                <Grid container alignItems="stretch" justifyContent="center" spacing={4}>
                    {examples.map((example, idx) => (
                        <Grid key={example.title} container item sm={6} lg={4}>
                            <Card sx={styles.card}>
                                <CustomIcon src={example.icon} alt={example.title} fill />
                                <TitleSecondary textAlign="left" component="h3">
                                    {example.title}
                                </TitleSecondary>
                                <List sx={{ flex: 1, py: 0 }}>
                                    {example.content.map((item) => {
                                        return <ListItem key={item}>{item}</ListItem>
                                    })}
                                </List>
                                {idx === examples.length - 1 && (
                                    <Box sx={styles.cardGlow}>
                                        <Image
                                            src="/svgs/backgrounds/card-glow.svg"
                                            alt="card glow"
                                            fill
                                            priority={false}
                                        />
                                    </Box>
                                )}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </SlideUpAnimation>
        </Section>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    card: {
        alignItems: "start",
        justifyContent: "center",
        background: alpha(theme.palette.secondary.main, 0.08),
        border: `2px solid ${alpha(theme.palette.common.white, 0.1)}`,
        boxShadow: "none",
        gap: 3,
        position: "relative",
        overflow: "hidden",
    },
    cardGlow: {
        position: "absolute",
        width: "70%",
        aspectRatio: 1,
        bottom: 0,
        right: 0,
        animation: animations.pulsate,
    },
}
