"use client"

import { Container, SxProps, Theme } from "@mui/material"
import { TrustBadges } from "@/app/components/TrustBadges"

export default function AsSeenIn() {
    return (
        <Container component="section" sx={styles.section}>
            <TrustBadges />
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    section: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        pt: 6.5,
        pb: 9,
    },
}
