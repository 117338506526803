"use client"
import { Box, SxProps, Theme } from "@mui/material"
import Image from "next/image"
import { Section } from "../layout/Section"
import { ExternalLink } from "../links/ExternalLink"
import { Title, TitleTertiary } from "../typography/Title"
import animations from "../utility/animations"
import SlideUpAnimation from "../utility/SlideUpAnimation"

export default function CrossPlatformSupport() {
    return (
        <Section>
            <SlideUpAnimation sx={styles.container}>
                <Title component="h2" maxWidth={700} hasBlueGradient>
                    Bring Order to Content Chaos
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.2s" sx={styles.container}>
                <TitleTertiary maxWidth={730} component="h3" color="text.secondary">
                    Store your content with peace of mind—offline first & accessible through the
                    browser extension, web & mobile apps.
                </TitleTertiary>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.4s" sx={{ ...styles.container, zIndex: 1 }}>
                <ExternalLink
                    target="_blank"
                    href="https://docs.getrecall.ai/getting-started/1-start-here"
                >
                    Explore Cross-Platform Support
                </ExternalLink>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.6s" sx={styles.container}>
                <Box sx={styles.imageContainer}>
                    <Image
                        alt="Display of cross-platform support"
                        fill
                        src="/images/hero/cross-platform.webp"
                        priority
                        quality={100}
                    />
                </Box>
                <Box sx={styles.bgRight}>
                    <Image
                        fill
                        src="/images/backgrounds/cross-platform-bg.webp"
                        alt="Cross-platform background glow"
                    />
                </Box>
                <Box sx={styles.bgLeft}>
                    <Image
                        fill
                        src="/images/backgrounds/cross-platform-bg.webp"
                        alt="Cross-platform background glow"
                        style={{
                            transform: "rotate(-90deg)",
                        }}
                    />
                </Box>
            </SlideUpAnimation>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        mb: 3,
    },
    imageContainer: {
        position: "relative",
        aspectRatio: 1.531,
        width: {
            xs: "90%",
            md: "70%",
        },
        mt: 2.5,
        zIndex: -1,
    },
    bgRight: {
        position: "absolute",
        top: {
            xs: "-15%",
            md: "-25%",
        },
        right: {
            xs: "-10%",
            md: "-3%",
        },
        aspectRatio: 0.946,
        width: "50%",
        zIndex: -2,
        animation: animations.pulsate,
    },
    bgLeft: {
        position: "absolute",
        bottom: {
            xs: "15%",
            md: 0,
        },
        left: {
            xs: "-10%",
            md: 0,
        },
        aspectRatio: 0.946,
        width: "50%",
        zIndex: -2,
        animation: animations.pulsate,
    },
}
