"use client"

import { Box, Fade, SxProps, Theme } from "@mui/material"
import anime from "animejs"
import Image from "next/image"
import { useEffect } from "react"
import { Ellipse } from "./Ellipse"
import { getTheme } from "@/theme"

export const AnimationSummarize = () => {
    const theme = getTheme();

    useEffect(() => {
        const pathElements = document.querySelectorAll('[id^="dotted-line"]')

        pathElements.forEach((elementPath: any, index) => {
            const ellipseEl = document.querySelector(`.ellipse-${index}`)

            const path = anime.path(elementPath)

            anime({
                targets: ellipseEl,
                translateX: path("x"),
                translateY: path("y"),
                easing: "linear",
                loop: true,
                duration: Math.floor(Math.random() * (5000 - 3000 + 1) + 3000),
            })
        })
    }, [])

    const handleClick = () => {
        const pathElements = document.querySelectorAll('[id^="dotted-line"]')

        pathElements.forEach((elementPath: any, index) => {
            const ellipseEl = document.querySelector(`.ellipse-${index}`)

            const path = anime.path(elementPath)

            anime({
                targets: ellipseEl,
                translateX: path("x"),
                translateY: path("y"),
                easing: "linear",
                duration: 1500,
            })
        })
    }

    return (
        <Fade in timeout={1300}>
            <Box
                sx={{
                    position: "relative",
                    width: { xs: "80%", lg: "71.255%", aspectRatio: "2/1" },
                    transform: "translateZ(0)",
                }}
            >
                <Box
                    component="svg"
                    sx={{
                        position: "absolute",
                        overflow: "visible !important",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: -1,
                    }}
                    viewBox="0 0 1200 600"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="Group 1167">
                        <path
                            id="dotted-line"
                            d="M278 36C579.49 42.9118 616.724 132.495 720.5 213.193C794.84 271 854.43 271 986 271"
                            stroke={theme.palette.primary.main}
                            strokeWidth="0.5"
                        />
                        <path
                            id="dotted-line_2"
                            d="M279 143C580.064 146.765 617.245 195.559 720.875 239.513C795.11 271 854.616 271 986 271"
                            stroke={theme.palette.primary.main}
                            strokeWidth="0.5"
                        />
                        <path
                            id="dotted-line_3"
                            d="M279 249C580.064 249.647 617.245 258.034 720.875 265.588C795.11 271 854.616 271 986 271"
                            stroke={theme.palette.primary.main}
                            strokeWidth="0.5"
                        />
                        <path
                            id="dotted-line_4"
                            d="M276 352C578.342 349.618 615.681 318.74 719.75 290.925C794.3 271 854.058 271 986 271"
                            stroke={theme.palette.primary.main}
                            strokeWidth="0.5"
                        />
                        <path
                            id="dotted-line_5"
                            d="M279 459C580.064 453.471 617.245 381.804 720.875 317.246C795.11 271 854.616 271 986 271"
                            stroke={theme.palette.primary.main}
                            strokeWidth="0.5"
                        />
                        <path
                            id="dotted-line_6"
                            d="M278 565C579.49 556.353 616.724 444.278 720.5 343.321C794.84 271 854.43 271 986 271"
                            stroke={theme.palette.primary.main}
                            strokeWidth="0.5"
                        />
                    </g>
                </Box>
                <Box
                    onClick={handleClick}
                    sx={{
                        width: "100%",
                        transition: "opacity 1s",
                        cursor: "pointer",
                    }}
                >
                    <Image
                        alt="Online content being summarized"
                        fill
                        src="/images/hero/online-content.webp"
                        priority
                    />
                </Box>

                <Ellipse index={0} sx={styles.ellipse} />
                <Ellipse index={1} sx={styles.ellipse} />
                <Ellipse index={2} sx={styles.ellipse} />
                <Ellipse index={3} sx={styles.ellipse} />
                <Ellipse index={4} sx={styles.ellipse} />
                <Ellipse index={5} sx={styles.ellipse} />
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    ellipse: {
        position: "absolute",
        top: "-1.5%",
        left: "-1.5%",
        width: "3%",
        height: "3%",
        willChange: "transform",
    },
}
