import { getTheme } from "@/theme"
import { ArrowForward } from "@mui/icons-material"
import { Box, SxProps, Theme } from "@mui/material"
import Link from "next/link"
import { PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
    href: string
    target?: string
}

export const ExternalLink = ({
    href,
    target = "_self",
    children,
}: Props) => {
    return (
        <Link href={href} target={target} style={{ textDecoration: "none" }}>
            <Box component="span" sx={{ ...style, }}>
                {children}
                <ArrowForward sx={{ ...style, }} />
            </Box>
        </Link>
    )
}

const theme = getTheme()

const style: SxProps<Theme> = {
    color: theme.palette.primary.main,
    transition: "opacity 0.2s ease-in-out",
    "&:hover": {
        opacity: 0.8,
    },
    display: "flex",
    alignItems: "center",
    gap: 1,
    textAlign: "left",
    fontSize: {
        xs: "12px",
        sm: "14px",
        md: "16px",
    },
}
