import { getTheme } from "@/theme";
import { Icon, SxProps, Theme } from "@mui/material"
import Image, { ImageProps } from "next/image";

interface Props extends ImageProps {
    sx?: SxProps<Theme>;
}
export const CustomIcon = ({ sx = {}, ...imgProps }: Props) => {
    const style = { ...styles.icon, ...sx }
    return (
        <Icon sx={style}>
            <Image {...imgProps} />
        </Icon>
    )
}

const theme = getTheme();

const styles: Record<string, SxProps<Theme>> = {
    icon: {
        position: "relative",
        width: "60px",
        height: "60px",
        [theme.breakpoints.down("md")]: {
            width: "50px",
            height: "50px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "40px",
            height: "40px",
        }
    },
}
