"use client"
import { Box, Fade, SxProps, Theme } from "@mui/material"
import Image from "next/image"
import animations from "../utility/animations"

export const AnimationChat = () => {
    return (
        <Fade in timeout={1300}>
            <Box
                sx={{
                    position: "relative",
                    width: { xs: "60%", lg: "50%", aspectRatio: "2/1" },
                }}
            >
                <Box sx={styles.background}>
                    <Image
                        fill
                        src="/images/backgrounds/chat-bg.webp"
                        alt="Chat feature background"
                    />
                </Box>
                <Box
                    component="img"
                    width="100%"
                    alt="Chat with recall for summarised content"
                    src="/images/chat.webp"
                />
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    background: {
        position: "absolute",
        top: "-70%",
        left: "-40%",
        aspectRatio: 1.16,
        width: "200%",
        zIndex: -2,
        animation: animations.pulsate,
    },
}