import {
    ListItemProps,
    ListProps,
    List as MuiList,
    ListItem as MuiListItem,
    SxProps,
    Theme,
} from "@mui/material"

export const List = ({ sx = {}, ...props }: ListProps) => {
    const style = { ...styles.list, ...sx }

    return (
        <MuiList {...props} sx={style}>
            {props.children}
        </MuiList>
    )
}

export const ListItem = (props: ListItemProps) => {
    return (
        <MuiListItem sx={styles.listItem} {...props}>
            {props.children}
        </MuiListItem>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    list: {
        pl: "16px",
        listStyle: "auto",
    },
    listItem: {
        pt: 0,
        pb: 1,
        pl: "0 !important",
        display: "list-item",
    },
}
