import { keyframes } from "@emotion/react";

const pulsate = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.005); opacity: 0.6; }
  100% { transform: scale(1); opacity: 1; }
`;

const pulseRing = keyframes`
  0% { transform: scale(0.1, 0.1); opacity: 0; }
  50% { opacity: 1.0; }
  100% { transform: scale(1.2, 1.2); opacity: 0; }
`

export default {
    pulsate: `${pulsate} 5s infinite ease-in-out`,
    pulseRing: `${pulseRing} 1s infinite ease-out`
}