"use client"
import { Box, SxProps, Theme } from "@mui/material"
import Image from "next/image"
import { useEffect, useRef, useState } from "react"
import { ButtonGroup } from "../buttons/ButtonGroup"
import { Section } from "../layout/Section"
import { ExternalLink } from "../links/ExternalLink"
import { Title, TitleSecondary, TitleTertiary } from "../typography/Title"
import animations from "../utility/animations"
import SlideUpAnimation from "../utility/SlideUpAnimation"

const videoUrl = {
    YouTube: "videos/website_youtube.mp4",
    PDFs: "videos/website_pdf.mp4",
    ["Google Docs"]: "videos/website_googledocs.mp4",
    Articles: "videos/website_article.mp4",
    Recipes: "videos/website_recipe.mp4",
}

type Action = "YouTube" | "PDFs" | "Google Docs" | "Articles" | "Recipes"
const actions: Action[] = ["YouTube", "Articles", "PDFs", "Google Docs", "Recipes"]

export default function SummaryExample() {
    const [active, setActive] = useState<string>(actions[0])
    const [isVisible, setIsVisible] = useState(false)
    const [preload, setPreload] = useState<Record<Action, string>>({
        YouTube: "auto",
        PDFs: "none",
        ["Google Docs"]: "none",
        Articles: "none",
        Recipes: "none",
    })
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            setIsVisible(entries[0].isIntersecting)
        })

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        actions.forEach((action) => {
            if (action !== active) {
                const video = document.getElementById(`id-${action}`) as HTMLVideoElement
                if (video && isVisible) {
                    video.currentTime = 0
                    video.pause()
                }
            }
        })

        const video = document.getElementById(`id-${active}`) as HTMLVideoElement
        if (video && isVisible) {
            video.currentTime = 0
            video.play()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    const hoverButton = (action: string) => {
        setPreload((prev) => ({ ...prev, [action]: "auto" }))
    }

    return (
        <Section>
            <SlideUpAnimation>
                <Title component="h2" hasBlueGradient>
                    Save hours every day
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.2s" sx={{ display: "flex", justifyContent: "center" }}>
                <TitleSecondary component="h3" color="text.secondary" sx={styles.secondaryTitle}>
                    Get to the point, faster
                </TitleSecondary>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.4s" sx={{ display: "flex", justifyContent: "center" }}>
                <TitleTertiary maxWidth={730} component="h4" color="text.secondary" mb={3}>
                    Focus on what matters with instant summaries & chat. <br />
                    Works best with YouTube, PDFs, Google Docs, Articles & more.
                </TitleTertiary>
            </SlideUpAnimation>
            <SlideUpAnimation
                delay="0.6s"
                sx={{ display: "flex", justifyContent: "center", pb: 3, zIndex: 1 }}
            >
                <ExternalLink
                    target="_blank"
                    href="https://docs.getrecall.ai/getting-started/2-add-content"
                >
                    Explore Adding Content
                </ExternalLink>
            </SlideUpAnimation>
            <SlideUpAnimation
                delay="0.8s"
                sx={{ position: "relative", display: "flex", justifyContent: "center" }}
            >
                <Box ref={ref} sx={styles.videoContainer}>
                    {actions.map((action) => (
                        <Box
                            id={`id-${action}`}
                            preload={preload[action]}
                            key={action}
                            sx={styles.video}
                            display={action === active ? "block" : "none"}
                            component="video"
                            autoPlay={active === action ? true : undefined}
                            muted
                            playsInline
                            loop
                        >
                            <source src={videoUrl[action]} />
                        </Box>
                    ))}
                    <ButtonGroup
                        actions={actions}
                        active={active}
                        setActive={setActive}
                        handleHover={hoverButton}
                        buttonGroupSx={{
                            border: "1px solid rgba(255, 255, 255, 0.05)",
                            background: "rgba(255, 255, 255, 0.06)",
                        }}
                    />
                </Box>
                <Box sx={styles.videoBg}>
                    <Image
                        fill
                        src="/images/backgrounds/summary-example-bg.webp"
                        alt="Summary example background glow"
                    />
                </Box>
            </SlideUpAnimation>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    video: {
        width: "100%",
        aspectRatio: "3456/2160",
        borderRadius: "10px",
        boxShadow: `0 0 20px 0px #000212, 0 0 5px 0px #000212`,
        border: "1px solid #ffffff20",
        mb: { xs: 2, md: 4 },
    },
    secondaryTitle: {
        fontSize: "35px",
        pb: 3,
    },
    videoContainer: {
        px: { xs: 2, sm: 4, md: 6 },
        py: { xs: 2, sm: 4, md: 6 },
        width: { xs: "100%", md: "70%" },
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#0D0F1E",
        border: "2px solid rgba(181, 209, 229, 0.10)",
        borderRadius: "20px",
        zIndex: -1,
    },
    videoBg: {
        top: "-40%",
        right: "-8%",
        position: "absolute",
        aspectRatio: 1.184,
        zIndex: -2,
        width: "130%",
        display: { xs: "none", md: "block" },
        animation: animations.pulsate,
    },
}
