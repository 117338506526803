"use client"

import { getTheme } from "@/theme"
import { Box, Button, SxProps, Theme, alpha } from "@mui/material"

interface Props {
    actions: string[]
    active: string
    setActive: Function
    handleHover?: (_: string) => void
    buttonGroupSx?: SxProps<Theme>
    buttonSx?: SxProps<Theme>
}

export const ButtonGroup = ({
    actions,
    active,
    setActive,
    buttonGroupSx,
    buttonSx,
    handleHover = () => {},
}: Props) => {
    const buttonGroupStyle = {
        ...styles.buttonGroup,
        ...(buttonGroupSx || {}),
    }

    const buttonStyle = {
        ...styles.button,
        ...(buttonSx || {}),
    }
    return (
        <Box sx={buttonGroupStyle}>
            {actions.map((action) => {
                const buttonColor =
                    active === action ? alpha(theme.palette.common.white, 0.05) : "none"
                return (
                    <Button
                        onMouseOver={() => handleHover(action)}
                        sx={{
                            ...buttonStyle,
                            background: buttonColor,
                            borderColor: buttonColor,
                        }}
                        onClick={() => setActive(action)}
                        key={action}
                    >
                        {action}
                    </Button>
                )
            })}
        </Box>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    buttonGroup: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 1,
        background: alpha(theme.palette.primary.main, 0.2),
        borderRadius: "30px",
        p: 1,
        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    },
    button: {
        borderRadius: 4,
        color: "white",
        fontSize: theme.typography.subtitle1,
        width: 130,
        fontWeight: 500,
        ":hover": {
            background: alpha(theme.palette.common.white, 0.05),
        },
        background: "none",
        outline: "none",
        border: "1px solid transparent",

        [theme.breakpoints.down("lg")]: {
            width: "auto",
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },
}
