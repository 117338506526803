"use client"
import { ROUTES } from "@/constants/routes"
import { Container, SxProps, Theme } from "@mui/material"
import { ActionButton } from "../buttons/ActionButton"
import { Paragraph } from "../typography/Paragraph"
import { Title, TitleSecondary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"

export default function Hero() {
    return (
        <Container component="section" sx={styles.section}>
            <SlideUpAnimation visible delay="0s">
                <Title fontWeight={700}>
                    Summarize Anything, <br />
                    Forget Nothing
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation visible delay="0s" sx={{ display: "flex", justifyContent: "center" }}>
                <TitleSecondary pb={3} maxWidth={800}>
                    Summarize content. Chat with it. Always recall it.
                </TitleSecondary>
            </SlideUpAnimation>
            <SlideUpAnimation visible delay="0s" sx={{ display: "flex", justifyContent: "center" }}>
                <Paragraph pb={3} maxWidth={700} responsive>
                    Transforming scattered content into a self-organizing knowledge base that grows
                    smarter the more you use it
                </Paragraph>
            </SlideUpAnimation>
            <SlideUpAnimation visible delay="0.1s">
                <ActionButton
                    sx={styles.button}
                    href={ROUTES.SIGNUP}
                    aria-label="Signup link button"
                >
                    Get Started
                </ActionButton>
            </SlideUpAnimation>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    section: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        mt: 2,
        position: "relative",
    },
    button: {
        mb: 6,
        mt: 2,
    },
}
