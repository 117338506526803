import { SxProps, Theme, Typography, TypographyProps } from "@mui/material"
import { PropsWithChildren } from "react"

type ParagraphProps = TypographyProps & { responsive?: boolean }

export const Paragraph = ({
    children,
    sx = {},
    responsive,
    ...props
}: PropsWithChildren<ParagraphProps>) => {
    const style = {
        ...sx,
        ...(responsive ? styles.responsive : {}),
    }

    return (
        <Typography mb={2} sx={style} {...props}>
            {children}
        </Typography>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    responsive: {
        fontSize: {
            xs: "12px",
            sm: "14px",
            md: "16px",
        },
    },
}
