"use client"
import { Box, SxProps, Theme } from "@mui/material"
import Image from "next/image"
import { Section } from "../layout/Section"
import { ExternalLink } from "../links/ExternalLink"
import { Title, TitleSecondary, TitleTertiary } from "../typography/Title"
import animations from "../utility/animations"
import SlideUpAnimation from "../utility/SlideUpAnimation"

export default function RAB() {
    return (
        <Section>
            <SlideUpAnimation sx={styles.container}>
                <Title component="h2" maxWidth={700} hasBlueGradient sx={{ pb: { xs: 3, md: 0 } }}>
                    Always Recall with Augmented Browsing
                </Title>
            </SlideUpAnimation>
            <Box sx={styles.content}>
                <Box display="flex" flexDirection="column" gap={3} flex={4}>
                    <SlideUpAnimation delay="0.2s">
                        <TitleSecondary
                            component="h3"
                            textAlign="left"
                            sx={{ color: "text.primary", fontSize: { lg: "30px" } }}
                        >
                            Never wonder where you have seen something before
                        </TitleSecondary>
                    </SlideUpAnimation>
                    <SlideUpAnimation delay="0.4s">
                        <TitleTertiary component="h4" textAlign="left" color="text.secondary">
                            Related content is automatically linked & stored in a knowledge graph.
                            As you browse, these connections resurface in real-time, turning passive
                            browsing into active discovery—all while keeping your data local &
                            secure.
                        </TitleTertiary>
                    </SlideUpAnimation>
                    <SlideUpAnimation delay="0.6s" sx={{ textAlign: "left", zIndex: 1 }}>
                        <ExternalLink
                            target="_blank"
                            href="https://docs.getrecall.ai/deep-dives/recall-augmented-browsing"
                        >
                            Experience the power of Augmented Browsing
                        </ExternalLink>
                    </SlideUpAnimation>
                </Box>
                {/* Need negative margin to make image position correctly */}
                <Box flex={6} mr="-8%" width={"100%"}>
                    <SlideUpAnimation delay="0.2s" sx={{ position: "relative" }}>
                        <Box sx={styles.imageContainer}>
                            <Image
                                alt="Recall augmented browsing infographic"
                                fill
                                src="/images/rab.webp"
                                priority
                                quality={100}
                            />
                        </Box>
                        <Box sx={styles.imageBgRight}>
                            <Image
                                alt="Recall augmented browsing background"
                                fill
                                src="/images/rab-bg-right.webp"
                            />
                        </Box>
                        <Box sx={styles.imageBgLeft}>
                            <Image
                                alt="Recall augmented browsing background"
                                fill
                                src="/images/rab-bg-left.webp"
                            />
                        </Box>
                    </SlideUpAnimation>
                </Box>
            </Box>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        textAlign: "center",
        gap: { xs: 2, md: 4 },
        width: { xs: "100%", md: "90%" },
        mx: "auto",
    },
    imageContainer: {
        position: "relative",
        aspectRatio: 1.753,
        width: "100%",
    },
    imageBgRight: {
        position: "absolute",
        width: "60%",
        aspectRatio: 1.17,
        bottom: "-12%",
        right: "-7%",
        zIndex: -1,
        animation: animations.pulsate,
    },
    imageBgLeft: {
        position: "absolute",
        width: "60%",
        aspectRatio: 0.86,
        bottom: "5%",
        left: "2%",
        zIndex: -1,
        animation: animations.pulsate,
    },
}
