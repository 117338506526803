"use client"
import { Box, Fade, SxProps, Theme } from "@mui/material"
import Image from "next/image"
import animations from "../utility/animations"

export const AnimationRecall = () => {
    return (
        <Fade in timeout={1300}>
            <Box
                sx={{
                    position: "relative",
                    width: { xs: "65%", lg: "60%", aspectRatio: "2/1" },
                }}
            >
                <Box sx={styles.background}>
                    <Image
                        fill
                        src="/images/backgrounds/recall-bg.webp"
                        alt="Recall feature background"
                    />
                </Box>
                <Box
                    component="img"
                    width="100%"
                    alt="Recall your knowlege base with augmented browsing"
                    src="/images/recall.webp"
                />
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    background: {
        position: "absolute",
        top: "-70%",
        right: "-35%",
        aspectRatio: 1.16,
        width: "180%",
        zIndex: -2,
        animation: animations.pulsate,
    },
}