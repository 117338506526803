"use client"

import { Box, Container, Fade, SxProps, Theme } from "@mui/material"
import { memo, PropsWithChildren, useState } from "react"
import { ButtonGroup } from "../buttons/ButtonGroup"
import { AnimationSummarize } from "./AnimationSummarize"
import { AnimationReview } from "./AnimationReview"
import { AnimationConnect } from "./AnimationConnect"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { AnimationChat } from "./AnimationChat"
import { AnimationRecall } from "./AnimationRecall"
import { TitleSecondary } from "../typography/Title"

const actions: (keyof typeof actionDescription)[] = [
    "Summarize",
    "Chat",
    "Connect",
    "Review",
    "Recall",
]
const actionDescription = {
    Summarize: "Cut through the noise with summaries of your content",
    Chat: "Chat with your content  - ask questions, get insights",
    Connect: "Discover unseen connections in your  knowledge graph",
    Review: "Enhance your memory with a spaced repetition schedule",
    Recall: "Resurface content from your knowledge base as you browse",
}

const ActionDescription = ({ children }: PropsWithChildren) => {
    return (
        <Fade in timeout={1300}>
            {/* Need a bit of negative margin to position text between image and button group nicely */}
            <Box sx={{ mt: { xs: 0, lg: -5 }, mb: { xs: 0, md: 5 } }}>
                <TitleSecondary component="h3" textAlign="center">
                    {children}
                </TitleSecondary>
            </Box>
        </Fade>
    )
}

export const HeroAnimationComponent = () => {
    const [active, setActive] = useState<keyof typeof actionDescription>(actions[0])

    return (
        <Container component="section" sx={styles.section}>
            <SlideUpAnimation visible delay="0.4s">
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Box sx={styles.container}>
                        <Box sx={styles.animationContainer}>
                            {active === "Summarize" && <AnimationSummarize />}
                            {active === "Chat" && <AnimationChat />}
                            {active === "Connect" && <AnimationConnect />}
                            {active === "Review" && <AnimationReview />}
                            {active === "Recall" && <AnimationRecall />}
                        </Box>
                        {/* It's easier position descriptions outside of individual animation containers due to image absolute positioning etc */}
                        <Box>
                            {active === "Summarize" && (
                                <ActionDescription>{actionDescription[active]}</ActionDescription>
                            )}
                            {active === "Chat" && (
                                <ActionDescription>{actionDescription[active]}</ActionDescription>
                            )}
                            {active === "Connect" && (
                                <ActionDescription>{actionDescription[active]}</ActionDescription>
                            )}
                            {active === "Review" && (
                                <ActionDescription>{actionDescription[active]}</ActionDescription>
                            )}
                            {active === "Recall" && (
                                <ActionDescription>{actionDescription[active]}</ActionDescription>
                            )}
                        </Box>
                        <ButtonGroup actions={actions} active={active} setActive={setActive} />
                    </Box>
                </Box>
            </SlideUpAnimation>
        </Container>
    )
}

export const HeroAnimation = memo(HeroAnimationComponent)

const styles: Record<string, SxProps<Theme>> = {
    section: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: { xs: 0, md: 4 },
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        gap: { xs: 2, lg: 1 },
    },
    // need this to ensure button group does not jump around vertically due to svg size differences
    animationContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: { xs: "120%", lg: "100%", aspectRatio: "2/1" },
    },
}
