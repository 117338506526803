"use client"

import { ROUTES } from "@/constants/routes"
import { alpha, Box, Grid, SxProps, Theme, Typography } from "@mui/material"
import Image from "next/image"
import { InlineLink } from "../links/InlineLink"
import { Section } from "../layout/Section"
import Card from "../surfaces/Card"
import { Title, TitleSecondary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"
import { CustomIcon } from "../icons/CustomIcon"
import FeaturesBackground from "./FeaturesBackground"
import animations from "../utility/animations"

const theme = getTheme()

const features = [
    {
        title: "Instant Summaries & Chat",
        image: "/svgs/icons/files-icon.svg",
        description: (
            <>
                Quality summaries & interactive chat with{" "}
                <InlineLink
                    color={theme.palette.secondary.light}
                    href={ROUTES.BLOGS.YOUTUBE_VIDE_SUMMARIZER}
                    target="_blank"
                >
                    YouTube
                </InlineLink>
                , Articles,{" "}
                <InlineLink
                    color={theme.palette.secondary.light}
                    href={ROUTES.BLOGS.PDF_SUMMARIZER}
                    target="_blank"
                >
                    PDFs
                </InlineLink>{" "}
                & more.
            </>
        ),
    },
    {
        title: "Augmented Browsing",
        image: "/svgs/icons/web-icon.svg",
        description: "Related content from your knowledge base is resurfaced as your browse.",
    },
    {
        title: "Self Organizing Knowledge ",
        image: "/svgs/icons/thunder-icon.svg",
        description: "Manual categorization is tedious - Recall uses AI to categorize content.",
    },
    {
        title: "Spaced Repetition",
        image: "/svgs/icons/book-icon.svg",
        description: (
            <>
                Enhance memory with a personal{" "}
                <InlineLink
                    color={theme.palette.secondary.light}
                    href={ROUTES.BLOGS.SPACED_REPETITION}
                    target="_blank"
                >
                    Spaced Repetition
                </InlineLink>{" "}
                schedule.
            </>
        ),
    },
    {
        title: "Automatic Knowledge Graph",
        image: "/svgs/icons/network-icon.svg",
        description: "Automatic linking of related content or custom links with one click.",
    },
    {
        title: "Secure, Private & Always Yours",
        image: "/svgs/icons/shield-icon.svg",
        description:
            "Offline first. Always exportable. Browsing is local first, saved content is stored in the cloud.",
    },
]

export default function Features() {
    return (
        <Section sx={{ position: "relative" }}>
            <SlideUpAnimation sx={styles.container}>
                <Title component="h2" mb={{ xs: 2, lg: 6 }} maxWidth={800} hasBlueGradient>
                    Move Beyond Traditional Knowledge Management
                </Title>
            </SlideUpAnimation>
            <Grid container alignItems="stretch" justifyContent="center" spacing={2}>
                {features.map((feature, index: number) => (
                    <Grid key={feature.title} container item md={6} lg={4}>
                        <SlideUpAnimation delay={`${0.1 * index}s`}>
                            <Card sx={styles.card}>
                                <CustomIcon src={feature.image} alt={feature.title} fill />
                                <TitleSecondary my={2} component="h3" fontWeight={600}>
                                    {feature.title}
                                </TitleSecondary>
                                <Typography
                                    variant="body1"
                                    minHeight={{ xs: "auto", md: 80 }}
                                    color={theme.palette.secondary.light}
                                >
                                    {feature.description}
                                </Typography>
                                {index === 1 && (
                                    <Box sx={styles.cardGlow}>
                                        <Image
                                            src="/svgs/backgrounds/card-glow.svg"
                                            alt="card glow"
                                            fill
                                            priority={false}
                                            style={{
                                                transform: "rotate(-90deg)",
                                            }}
                                        />
                                    </Box>
                                )}
                            </Card>
                        </SlideUpAnimation>
                    </Grid>
                ))}
            </Grid>
            <Box sx={styles.featuresBackground}>
                <FeaturesBackground />
            </Box>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    featuresBackground: {
        position: "absolute",
        width: "210%",
        bottom: "2.5%",
        aspectRatio: 3.23,
        zIndex: -1,
        display: { xs: "none", lg: "block" },
    },
    card: {
        justifyContent: "center",
        background: theme.palette.background.paper,
        border: `2px solid ${alpha(theme.palette.common.white, 0.1)}`,
        boxShadow: "none",
        position: "relative",
        overflow: "hidden",
        gap: 1,
    },
    cardGlow: {
        position: "absolute",
        width: "40%",
        aspectRatio: 1,
        top: 0,
        right: 0,
        animation: animations.pulsate
    },
}
