"use client"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"
import { Section } from "../layout/Section"
import Image from "next/image"
import { useEffect, useState } from "react"

const pathData = [
    "M431 96.4634H527V192.463H431V96.4634Z",
    "M1295 96.4634H1391V192.463H1295V96.4634Z",
    "M623 192.463H719V288.463H623V192.463Z",
    "M1007 192.463H1103V288.463H1007V192.463Z",
    "M143 288.463H239V384.463H143V288.463Z",
    "M1295 288.463H1391V384.463H1295V288.463Z",
    "M719 384.463H815V480.463H719V384.463Z",
    "M431 480.463H527V576.463H431V480.463Z",
    "M1007 480.463H1103V576.463H1007V480.463Z",
];

export default function SteveJobs() {
    const [hiddenBlocks, setHiddenBlocks] = useState<number[]>([]);

    useEffect(() => {
        const interval = setInterval(() => {
            const randomHiddenBlocks = Math.floor(Math.random() * pathData.length) + 1
            const newHiddenBlocks = Array.from({ length: randomHiddenBlocks }, () =>
                Math.floor(Math.random() * pathData.length)
            );
            setHiddenBlocks(newHiddenBlocks);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Section sx={styles.section}>
            <SlideUpAnimation sx={{ display: "flex", justifyContent: "center", width: "60%" }}>
                <Box sx={styles.textContainer}>
                    <Typography component="h2" variant="h2" sx={styles.quote}>
                        &ldquo;Creativity is just connecting things.&rdquo;
                    </Typography>
                    <Typography component="h3" sx={styles.author}>
                        Steve Jobs
                    </Typography>
                </Box>
            </SlideUpAnimation>
            {/* Overlay highlighted blocks with original svg */}
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <Image
                    fill
                    src="/svgs/backgrounds/steve-jobs-bg.svg"
                    alt="Background for quote section"
                />
            </Box>
            <Box
                id="quotes-bg-overlay"
                component="svg"
                sx={{
                    position: "absolute",
                    overflow: "visible !important",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                }}
                viewBox="0 0 1728 577"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip1_3995_4430)">
                    {pathData.map((d, index) => (
                        <path
                            key={`quotes-bg-path-${index}`}
                            d={d}
                            fill="#A0C2EE"
                            fillOpacity={hiddenBlocks.includes(index) ? "0" : "0.08"}
                            style={{
                                transition: "fill-opacity 0.5s ease-in-out"
                            }}
                        />
                    ))}
                </g>
            </Box>
        </Section>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    section: {
        position: "relative",
        my: { xs: 2, sm: 8, lg: 0 },
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: 1,
    },
    quote: {
        textAlign: "left",
        fontWeight: 600,
        fontSize: "80px",
        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.common.white})`,
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
        [theme.breakpoints.down("xl")]: {
            fontSize: "64px",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "48px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "32px",
        },
    },
    author: {
        color: "text.secondary",
        fontStyle: "italic",
    },
    background: {
        position: "absolute",
        width: "100%",
        aspectRatio: 3.33,
    },
}
