"use client"

import { Box, Fade, SxProps, Theme } from "@mui/material"
import anime from "animejs"
import { useEffect, useRef } from "react"
import { Ellipse } from "./Ellipse"

function getRandomInt(max: number, min = 0): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function runAnimation(connectEllipse: number) {
    const pathElements = document.querySelectorAll('[id^="id-connect-line"]')
    const index = getRandomInt(pathElements.length - 1)
    if (!pathElements[index]) return

    // @ts-ignore
    const path = anime.path(pathElements[index])
    const ellipseEl = document.querySelector(`.ellipse-${connectEllipse}`)
    const duration = Math.floor(Math.random() * (1200 - 800 + 1) + 800)

    anime({
        targets: ellipseEl,
        translateX: path("x"),
        translateY: path("y"),
        easing: "linear",
        duration: duration,
        complete: () => runAnimation(connectEllipse),
    })
}

export const AnimationConnect = () => {
    const animationInitialized = useRef(false)

    useEffect(() => {
        if (!animationInitialized.current) {
            runAnimation(0)
            runAnimation(1)
            runAnimation(2)
            animationInitialized.current = true
        }
    }, [])

    return (
        <Fade in timeout={1300}>
            <Box
                sx={{
                    position: "relative",
                    width: { xs: "80%", lg: "70%", aspectRatio: "2/1" },
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        overflow: "visible !important",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                    component="svg"
                    className="connect-lines"
                    width="1200"
                    height="600"
                    viewBox="0 0 1200 600"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="lines">
                        <path
                            id="id-connect-line"
                            d="M327.012 442.502L361 390"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_1"
                            d="M350.764 493.985L686 299"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_2"
                            d="M985.54 154.143L1059.58 291.293"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_3"
                            d="M893.296 154.142L780.842 243.559"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_4"
                            d="M930.258 154.143L926.066 364.116"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_5"
                            d="M635.516 453.643L509 377"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_6"
                            d="M350.763 513.403L464.853 551.028"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_7"
                            d="M263.378 444.222L188.127 349.552"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_8"
                            d="M291.293 444.221L315.834 309.264"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_9"
                            d="M276.727 144.433L312.451 252.613"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_10"
                            d="M223.323 144.432L175 297"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_11"
                            d="M311.924 144.433L468 335"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                        <path
                            id="id-connect-line_12"
                            d="M351.978 144.432L482.812 253.39"
                            stroke="white"
                            strokeWidth="1.61948"
                            opacity="0"
                        />
                    </g>
                </Box>
                <Box
                    width="100%"
                    height="100%"
                    component="img"
                    alt="Content being connected in the Recall knowledge base"
                    src="/images/hero/connections.webp"
                />
                <Ellipse index={0} sx={styles.ellipse} />
                <Ellipse index={1} sx={styles.ellipse} />
                <Ellipse index={2} sx={styles.ellipse} />
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    ellipseBg: { position: "absolute", overflow: "visible !important", width: "40%", zIndex: -1 },
    ellipse: {
        position: "absolute",
        top: "-2.5%",
        left: "-1.5%",
        width: "3%",
        height: "3%",
    },
}
