"use client"

import { Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material"
import { Section } from "../layout/Section"
import { useState } from "react"
import Image from "next/image"

export default function Demo() {
    const [hasHover, setHasHover] = useState(false)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Section sx={{ position: "relative", my: { xs: 2, sm: 8, lg: 4 }, zIndex: 1000 }}>
            <Box
                sx={styles.video}
                component="video"
                onMouseEnter={() => setHasHover(true)}
                onMouseLeave={() => setHasHover(false)}
                controls={hasHover || isMobile}
                poster="images/recall-demo-thumbnail.webp"
            >
                <source src="https://firebasestorage.googleapis.com/v0/b/recall-public/o/static_assets%2Fvideo%2Frecall-demo-020425.mp4?alt=media&token=d84717b2-01ca-4974-b8ae-df274434b35a" />
                Your browser does not support the video tag.
            </Box>
            <Box sx={styles.bgTop}>
                <Image
                    src="/svgs/backgrounds/demo-bg-top.svg"
                    alt="Demo background top"
                    fill
                    priority={false}
                />
            </Box>
            <Box sx={styles.bgBottom}>
                <Image
                    src="/svgs/backgrounds/demo-bg-bottom.svg"
                    alt="Demo background bottom"
                    fill
                    priority={false}
                />
            </Box>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    video: {
        width: { xs: "100%", lg: "80%" },
        height: "100%",
        borderRadius: 1,
        border: "1px solid #ffffff20",
        ":hover": {
            cursor: "pointer",
        },
    },
    bgTop: {
        position: "absolute",
        width: "100%",
        aspectRatio: 6.35,
        top: { xs: "-2%", lg: 0 },
        right: 0,
        zIndex: -1,
    },
    bgBottom: {
        position: "absolute",
        width: "100%",
        aspectRatio: 6.16,
        bottom: { xs: "-2%", lg: 0 },
        right: 0,
        zIndex: -1,
    },
}
