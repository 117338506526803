"use client"

import { Avatar, Box, Grid, SxProps, Theme, Typography, alpha } from "@mui/material"
import { Section } from "../layout/Section"
import Card from "../surfaces/Card"
import { Title } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"
import { CustomIcon } from "../icons/CustomIcon"

const testimonials = [
    {
        name: "William Peltier",
        position: "Senior Director at Moody's",
        image: "/images/testimonials/william_peltier.webp",
        quote: "Recall has revolutionized how I manage information! As a power user, its AI-driven summarization and storage capabilities have boosted my productivity tenfold, allowing me to effortlessly access and recall key details whenever I need them.",
    },
    {
        name: "Jason Patel",
        position: "Co-founder at Open Forge AI",
        image: "/images/testimonials/jason_patel.webp",
        quote: "I use Recall every day. It helps me quickly condense important information from thought leadership articles and educational YouTube videos. It's great to have place where I can easily store this information knowing that I will come back to it again in the future. I've greatly developed as a founder and leader thanks to Recall.",
    },
    {
        name: "Dave Katague",
        position: "AI Educator",
        image: "/images/testimonials/dave_katague.webp",
        quote: "I've been building several second brains for years and whenever I see a new way to do it, I don't like changing because my other systems already work. But I'm always willing to try because you never know which one would be a drastic improvement on what you're already using. Recall is one of them.",
    },
]

const theme = getTheme()

export default function Testimonials() {
    return (
        <Section>
            <SlideUpAnimation sx={styles.container}>
                <Title component="h2" mb={{ xs: 2, lg: 6 }} hasBlueGradient maxWidth={700}>
                    Used by Nerds Around the World
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.4s">
                <Grid container alignItems="stretch" justifyContent="center" spacing={4}>
                    {testimonials.map((testimonial) => (
                        <Grid key={testimonial.name} container item md={6} lg={4}>
                            <Card
                                sx={styles.card}
                                glow={{
                                    position: "50% 100%",
                                    color: alpha(theme.palette.common.white, 0.15),
                                }}
                            >
                                <Box sx={styles.avatarWrapper}>
                                    <Avatar
                                        src={testimonial.image}
                                        alt={testimonial.name}
                                        sx={{
                                            width: 60,
                                            height: 60,
                                        }}
                                    />
                                </Box>
                                <Box display="flex" flexDirection="column" gap={1} flex={1}>
                                    <CustomIcon src="/svgs/icons/quote-icon.svg" alt="Card quote icon" fill />
                                    <Typography textAlign="left" variant="body1">
                                        {testimonial.quote}
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="start" my={1} position="relative" left={47}>
                                    <Typography
                                        variant="h5"
                                        component="h3"
                                        fontWeight={600}
                                        sx={{ fontSize: { xs: 20, md: 24 } }}
                                    >
                                        {testimonial.name}
                                    </Typography>
                                    <Typography
                                        my={2}
                                        variant="caption"
                                        color="text.secondary"
                                        fontWeight={500}
                                        mt={0}
                                    >
                                        {testimonial.position}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </SlideUpAnimation>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    card: {
        justifyContent: "space-between",
        alignItems: "start",
        border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
        boxShadow: "none",
        position: "relative",
        gap: 2,
        pb: 1,
        borderRadius: "20px",
        borderTop: "none"
    },
    avatarWrapper: {
        borderRadius: 100,
        p: 0.6,
        backgroundColor: alpha("#FFF", 0.2),
        boxShadow: "0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset",
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "translate(-20%, 20%)",
    },
}
