import { Box } from "@mui/material"
import { useEffect, useRef } from "react"

const PATHS_TO_UPDATE = 200

export default function FeaturesBackground() {
    const svgRef = useRef<SVGSVGElement>(null)

    useEffect(() => {
        const updatePathsOpacity = () => {
            if (!svgRef.current) return

            const pathElements = svgRef.current.querySelectorAll("path")
            const lookup = new Set<number>()
            while (lookup.size < PATHS_TO_UPDATE) {
                lookup.add(Math.floor(Math.random() * pathElements.length))
            }

            const randomPathIndices = Array.from(lookup)
            randomPathIndices.forEach((index) => {
                const path = pathElements[index]
                if (path) {
                    path.style.transition = "opacity 0.8s ease-in-out"
                    path.style.opacity = (Math.random() * 2).toString()
                }
            })
        }

        const interval = setInterval(updatePathsOpacity, 500)

        return () => clearInterval(interval)
    }, [])

    return (
        <Box
            ref={svgRef}
            component="svg"
            viewBox="0 0 1728 801"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
                position: "absolute",
                overflow: "visible !important",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
            }}
        >
            <g clipPath="url(#clip0_3995_4511)">
                <path
                    d="M19.623 299.207V290.48H10.8961V299.207H19.623Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M71.9863 299.207V290.48H63.2594V299.207H71.9863Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M124.348 290.48H115.621V299.207H124.348V290.48Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M176.709 290.48H167.982V299.207H176.709V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M229.071 290.48H220.344V299.207H229.071V290.48Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M281.432 290.48H272.705V299.207H281.432V290.48Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M333.793 299.207V290.48H325.066V299.207H333.793Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M386.156 299.207V290.48H377.429V299.207H386.156Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M438.518 299.207V290.48H429.791V299.207H438.518Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M490.879 290.48H482.152V299.207H490.879V290.48Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M543.241 290.48H534.514V299.207H543.241V290.48Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M595.602 290.48H586.875V299.207H595.602V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M647.963 290.48H639.236V299.207H647.963V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M700.325 290.48H691.598V299.207H700.325V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M752.688 290.48H743.961V299.207H752.688V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M805.049 299.207V290.48H796.322V299.207H805.049Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M857.41 299.207V290.48H848.683V299.207H857.41Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M909.771 299.207V290.48H901.045V299.207H909.771Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M962.133 290.48H953.406V299.207H962.133V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M19.6234 342.842H10.8965V351.569H19.6234V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M71.9848 342.842H63.2578V351.569H71.9848V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M124.348 342.842H115.621V351.569H124.348V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M176.709 342.842H167.982V351.569H176.709V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M229.071 342.842H220.344V351.569H229.071V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M281.432 342.842H272.705V351.569H281.432V342.842Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M333.793 342.842H325.066V351.569H333.793V342.842Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M386.155 342.842H377.428V351.569H386.155V342.842Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M438.518 342.842H429.791V351.569H438.518V342.842Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M490.879 342.842H482.152V351.569H490.879V342.842Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M543.24 351.569V342.842H534.513V351.569H543.24Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M595.602 351.569V342.842H586.875V351.569H595.602Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M647.963 351.569V342.842H639.236V351.569H647.963Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M700.325 342.842H691.598V351.569H700.325V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M752.688 342.842H743.961V351.569H752.688V342.842Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M805.049 342.842H796.322V351.569H805.049V342.842Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M857.411 342.842H848.684V351.569H857.411V342.842Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M909.771 351.569V342.842H901.045V351.569H909.771Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M962.133 351.569V342.842H953.406V351.569H962.133Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M19.6234 395.203H10.8965V403.93H19.6234V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M71.9848 395.203H63.2578V403.93H71.9848V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M176.709 395.203H167.982V403.93H176.709V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M229.071 395.203H220.344V403.93H229.071V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M281.432 403.93V395.203H272.705V403.93H281.432Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M333.793 403.93V395.203H325.066V403.93H333.793Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M386.156 403.93V395.203H377.429V403.93H386.156Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M438.518 395.203H429.791V403.93H438.518V395.203Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M490.879 395.203H482.152V403.93H490.879V395.203Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M543.241 395.203H534.514V403.93H543.241V395.203Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M595.602 395.203H586.875V403.93H595.602V395.203Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M647.963 395.203H639.236V403.93H647.963V395.203Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M700.325 395.203H691.598V403.93H700.325V395.203Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M752.688 403.93V395.203H743.961V403.93H752.688Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M805.049 403.93V395.203H796.322V403.93H805.049Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M857.411 395.203H848.684V403.93H857.411V395.203Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M909.772 395.203H901.045V403.93H909.772V395.203Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M962.133 395.203H953.406V403.93H962.133V395.203Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M19.6234 447.565H10.8965V456.292H19.6234V447.565Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M71.9848 447.565H63.2578V456.292H71.9848V447.565Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M124.348 447.565H115.621V456.292H124.348V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M176.709 447.565H167.982V456.292H176.709V447.565Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M229.071 447.565H220.344V456.292H229.071V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M281.432 447.565H272.705V456.292H281.432V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M333.793 456.292V447.565H325.066V456.292H333.793Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M386.156 456.292V447.565H377.429V456.292H386.156Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M438.518 447.565H429.791V456.292H438.518V447.565Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M490.879 456.292V447.565H482.152V456.292H490.879Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M543.241 447.565H534.514V456.292H543.241V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M595.602 456.292V447.565H586.875V456.292H595.602Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M647.963 456.292V447.565H639.236V456.292H647.963Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M700.326 456.292V447.565H691.599V456.292H700.326Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M752.688 456.292V447.565H743.961V456.292H752.688Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M962.133 447.565H953.406V456.292H962.133V447.565Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M281.432 499.927H272.705V508.654H281.432V499.927Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M333.793 508.654V499.927H325.066V508.654H333.793Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M386.155 499.927H377.428V508.654H386.155V499.927Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M438.518 499.927H429.791V508.654H438.518V499.927Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M490.879 499.927H482.152V508.654H490.879V499.927Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M543.24 508.654V499.927H534.513V508.654H543.24Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M595.602 508.654V499.927H586.875V508.654H595.602Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M647.963 508.654V499.927H639.236V508.654H647.963Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M700.325 499.927H691.598V508.654H700.325V499.927Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M752.688 499.927H743.961V508.654H752.688V499.927Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M805.049 499.927H796.322V508.654H805.049V499.927Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M857.411 499.927H848.684V508.654H857.411V499.927Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M909.772 499.927H901.045V508.654H909.772V499.927Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M962.133 499.927H953.406V508.654H962.133V499.927Z"
                    fill="white"
                    fillOpacity="0.2"
                />
            </g>
            <g clipPath="url(#clip1_3995_4511)">
                <path
                    d="M1015.03 290.48H1006.3V299.207H1015.03V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1067.39 290.48H1058.67V299.207H1067.39V290.48Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1119.76 290.48H1111.03V299.207H1119.76V290.48Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1172.12 290.48H1163.39V299.207H1172.12V290.48Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1224.48 290.48H1215.75V299.207H1224.48V290.48Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1276.84 299.207V290.48H1268.11V299.207H1276.84Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1329.2 299.207V290.48H1320.47V299.207H1329.2Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1381.56 299.207V290.48H1372.84V299.207H1381.56Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1433.93 290.48H1425.2V299.207H1433.93V290.48Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1486.29 290.48H1477.56V299.207H1486.29V290.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1538.65 290.48H1529.92V299.207H1538.65V290.48Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1591.01 290.48H1582.28V299.207H1591.01V290.48Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1643.37 299.207V290.48H1634.64V299.207H1643.37Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1695.73 299.207V290.48H1687.01V299.207H1695.73Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1015.03 342.842H1006.3V351.569H1015.03V342.842Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1067.39 342.842H1058.67V351.569H1067.39V342.842Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1119.76 342.842H1111.03V351.569H1119.76V342.842Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1172.12 351.569V342.842H1163.39V351.569H1172.12Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1224.48 351.569V342.842H1215.75V351.569H1224.48Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1276.84 351.569V342.842H1268.11V351.569H1276.84Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1329.2 342.842H1320.47V351.569H1329.2V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1381.56 342.842H1372.84V351.569H1381.56V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1433.93 342.842H1425.2V351.569H1433.93V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1486.29 342.842H1477.56V351.569H1486.29V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1538.65 342.842H1529.92V351.569H1538.65V342.842Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1591.01 342.842H1582.28V351.569H1591.01V342.842Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1643.37 342.842H1634.64V351.569H1643.37V342.842Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1695.73 342.842H1687.01V351.569H1695.73V342.842Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1067.39 395.203H1058.67V403.93H1067.39V395.203Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1119.76 395.203H1111.03V403.93H1119.76V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1172.12 395.203H1163.39V403.93H1172.12V395.203Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1224.48 395.203H1215.75V403.93H1224.48V395.203Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1276.84 395.203H1268.11V403.93H1276.84V395.203Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1329.2 395.203H1320.47V403.93H1329.2V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1381.56 395.203H1372.84V403.93H1381.56V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1486.29 395.203H1477.56V403.93H1486.29V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1538.65 395.203H1529.92V403.93H1538.65V395.203Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1591.01 403.93V395.203H1582.28V403.93H1591.01Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1643.37 403.93V395.203H1634.64V403.93H1643.37Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1695.73 403.93V395.203H1687.01V403.93H1695.73Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1172.12 447.565H1163.39V456.292H1172.12V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1224.48 447.565H1215.75V456.292H1224.48V447.565Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1276.84 447.565H1268.11V456.292H1276.84V447.565Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1329.2 447.565H1320.47V456.292H1329.2V447.565Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1381.56 447.565H1372.84V456.292H1381.56V447.565Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1433.93 447.565H1425.2V456.292H1433.93V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1486.29 447.565H1477.56V456.292H1486.29V447.565Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1538.65 447.565H1529.92V456.292H1538.65V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1591.01 447.565H1582.28V456.292H1591.01V447.565Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1643.37 456.292V447.565H1634.64V456.292H1643.37Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1695.73 456.292V447.565H1687.01V456.292H1695.73Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1591.01 499.927H1582.28V508.654H1591.01V499.927Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1643.37 508.654V499.927H1634.64V508.654H1643.37Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1695.73 499.927H1687.01V508.654H1695.73V499.927Z"
                    fill="white"
                    fillOpacity="0.2"
                />
            </g>
            <g clipPath="url(#clip2_3995_4511)">
                <path
                    d="M21.4883 556.646V547.919H12.7613V556.646H21.4883Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M73.8516 556.646V547.919H65.1246V556.646H73.8516Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M126.213 547.919H117.486V556.646H126.213V547.919Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M178.575 547.919H169.848V556.646H178.575V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M230.936 547.919H222.209V556.646H230.936V547.919Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M283.297 547.919H274.57V556.646H283.297V547.919Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M335.658 556.646V547.919H326.931V556.646H335.658Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M388.021 556.646V547.919H379.295V556.646H388.021Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M440.383 556.646V547.919H431.656V556.646H440.383Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M492.745 547.919H484.018V556.646H492.745V547.919Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M545.106 547.919H536.379V556.646H545.106V547.919Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M597.467 547.919H588.74V556.646H597.467V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.829 547.919H641.102V556.646H649.829V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M702.19 547.919H693.463V556.646H702.19V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M754.553 547.919H745.826V556.646H754.553V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 556.646V547.919H798.187V556.646H806.914Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M859.275 556.646V547.919H850.548V556.646H859.275Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M911.637 556.646V547.919H902.91V556.646H911.637Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M963.998 547.919H955.271V556.646H963.998V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M21.4887 600.28H12.7617V609.007H21.4887V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M73.85 600.28H65.123V609.007H73.85V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M126.213 600.28H117.486V609.007H126.213V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M178.575 600.28H169.848V609.007H178.575V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M230.936 600.28H222.209V609.007H230.936V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M283.297 600.28H274.57V609.007H283.297V600.28Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M335.659 600.28H326.932V609.007H335.659V600.28Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M388.02 600.28H379.293V609.007H388.02V600.28Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M440.383 600.28H431.656V609.007H440.383V600.28Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M492.745 600.28H484.018V609.007H492.745V600.28Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M545.105 609.007V600.28H536.379V609.007H545.105Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M597.467 609.007V600.28H588.74V609.007H597.467Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M649.828 609.007V600.28H641.101V609.007H649.828Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M702.19 600.28H693.463V609.007H702.19V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M754.553 600.28H745.826V609.007H754.553V600.28Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 600.28H798.188V609.007H806.914V600.28Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M859.276 600.28H850.549V609.007H859.276V600.28Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M911.637 609.007V600.28H902.91V609.007H911.637Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M964 609.007V600.28H955.273V609.007H964Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M21.4887 652.642H12.7617V661.369H21.4887V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M73.85 652.642H65.123V661.369H73.85V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M178.575 652.642H169.848V661.369H178.575V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M230.936 652.642H222.209V661.369H230.936V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M283.297 661.369V652.642H274.57V661.369H283.297Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M335.658 661.369V652.642H326.931V661.369H335.658Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M388.021 661.369V652.642H379.295V661.369H388.021Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M440.383 652.642H431.656V661.369H440.383V652.642Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M492.745 652.642H484.018V661.369H492.745V652.642Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M545.106 652.642H536.379V661.369H545.106V652.642Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M597.467 652.642H588.74V661.369H597.467V652.642Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.829 652.642H641.102V661.369H649.829V652.642Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M702.19 652.642H693.463V661.369H702.19V652.642Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M754.553 661.369V652.642H745.826V661.369H754.553Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 661.369V652.642H798.187V661.369H806.914Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M859.276 652.642H850.549V661.369H859.276V652.642Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M911.637 652.642H902.91V661.369H911.637V652.642Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M963.998 652.642H955.271V661.369H963.998V652.642Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M21.4887 705.004H12.7617V713.731H21.4887V705.004Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M73.85 705.004H65.123V713.731H73.85V705.004Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M126.213 705.004H117.486V713.731H126.213V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M178.575 705.004H169.848V713.731H178.575V705.004Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M230.936 705.004H222.209V713.731H230.936V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M283.297 705.004H274.57V713.731H283.297V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M335.658 713.731V705.004H326.931V713.731H335.658Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M388.021 713.731V705.004H379.295V713.731H388.021Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M440.383 705.004H431.656V713.731H440.383V705.004Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M492.744 713.731V705.004H484.017V713.731H492.744Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M545.106 705.004H536.379V713.731H545.106V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M597.467 713.731V705.004H588.74V713.731H597.467Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.828 713.731V705.004H641.101V713.731H649.828Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M702.191 713.731V705.004H693.464V713.731H702.191Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M754.553 713.731V705.004H745.826V713.731H754.553Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M963.998 705.004H955.271V713.731H963.998V705.004Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M283.297 757.365H274.57V766.092H283.297V757.365Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M335.658 766.092V757.365H326.931V766.092H335.658Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M388.02 757.365H379.293V766.092H388.02V757.365Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M440.383 757.365H431.656V766.092H440.383V757.365Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M492.745 757.365H484.018V766.092H492.745V757.365Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M545.105 766.092V757.365H536.379V766.092H545.105Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M597.467 766.092V757.365H588.74V766.092H597.467Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.828 766.092V757.365H641.101V766.092H649.828Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M702.19 757.365H693.463V766.092H702.19V757.365Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M754.553 757.365H745.826V766.092H754.553V757.365Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 757.365H798.188V766.092H806.914V757.365Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M859.276 757.365H850.549V766.092H859.276V757.365Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M911.637 757.365H902.91V766.092H911.637V757.365Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M963.998 757.365H955.271V766.092H963.998V757.365Z"
                    fill="white"
                    fillOpacity="0.2"
                />
            </g>
            <g clipPath="url(#clip3_3995_4511)">
                <path
                    d="M1016.89 547.919H1008.17V556.646H1016.89V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1069.26 547.919H1060.53V556.646H1069.26V547.919Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1121.62 547.919H1112.89V556.646H1121.62V547.919Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1173.98 547.919H1165.25V556.646H1173.98V547.919Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1226.34 547.919H1217.62V556.646H1226.34V547.919Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1278.7 556.646V547.919H1269.98V556.646H1278.7Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1331.06 556.646V547.919H1322.34V556.646H1331.06Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1383.43 556.646V547.919H1374.7V556.646H1383.43Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1435.79 547.919H1427.06V556.646H1435.79V547.919Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1488.15 547.919H1479.42V556.646H1488.15V547.919Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1540.51 547.919H1531.79V556.646H1540.51V547.919Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1592.87 547.919H1584.15V556.646H1592.87V547.919Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1645.23 556.646V547.919H1636.51V556.646H1645.23Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1697.6 556.646V547.919H1688.87V556.646H1697.6Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1016.89 600.28H1008.17V609.007H1016.89V600.28Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1069.26 600.28H1060.53V609.007H1069.26V600.28Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1121.62 600.28H1112.89V609.007H1121.62V600.28Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1173.98 609.007V600.28H1165.25V609.007H1173.98Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1226.34 609.007V600.28H1217.61V609.007H1226.34Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1278.7 609.007V600.28H1269.98V609.007H1278.7Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1331.06 600.28H1322.34V609.007H1331.06V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1383.43 600.28H1374.7V609.007H1383.43V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1435.79 600.28H1427.06V609.007H1435.79V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1488.15 600.28H1479.42V609.007H1488.15V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1540.51 600.28H1531.79V609.007H1540.51V600.28Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1592.87 600.28H1584.15V609.007H1592.87V600.28Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1645.23 600.28H1636.51V609.007H1645.23V600.28Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1697.6 600.28H1688.87V609.007H1697.6V600.28Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1069.26 652.642H1060.53V661.369H1069.26V652.642Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1121.62 652.642H1112.89V661.369H1121.62V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1173.98 652.642H1165.25V661.369H1173.98V652.642Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1226.34 652.642H1217.62V661.369H1226.34V652.642Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1278.7 652.642H1269.98V661.369H1278.7V652.642Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1331.06 652.642H1322.34V661.369H1331.06V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1383.43 652.642H1374.7V661.369H1383.43V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1488.15 652.642H1479.42V661.369H1488.15V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1540.51 652.642H1531.79V661.369H1540.51V652.642Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1592.87 661.369V652.642H1584.15V661.369H1592.87Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1645.23 661.369V652.642H1636.51V661.369H1645.23Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1697.6 661.369V652.642H1688.87V661.369H1697.6Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1173.98 705.004H1165.25V713.731H1173.98V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1226.34 705.004H1217.62V713.731H1226.34V705.004Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1278.7 705.004H1269.98V713.731H1278.7V705.004Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1331.06 705.004H1322.34V713.731H1331.06V705.004Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1383.43 705.004H1374.7V713.731H1383.43V705.004Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1435.79 705.004H1427.06V713.731H1435.79V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1488.15 705.004H1479.42V713.731H1488.15V705.004Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1540.51 705.004H1531.79V713.731H1540.51V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1592.87 705.004H1584.15V713.731H1592.87V705.004Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1645.23 713.731V705.004H1636.51V713.731H1645.23Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1697.6 713.731V705.004H1688.87V713.731H1697.6Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1592.87 757.365H1584.15V766.092H1592.87V757.365Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1645.23 766.092V757.365H1636.51V766.092H1645.23Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1697.6 757.365H1688.87V766.092H1697.6V757.365Z"
                    fill="white"
                    fillOpacity="0.2"
                />
            </g>
            <g clipPath="url(#clip4_3995_4511)">
                <path
                    d="M21.4883 43.6351V34.9082H12.7613V43.6351H21.4883Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M73.8516 43.6351V34.9082H65.1246V43.6351H73.8516Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M126.213 34.9082H117.486V43.6351H126.213V34.9082Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M178.575 34.9082H169.848V43.6351H178.575V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M230.936 34.9082H222.209V43.6351H230.936V34.9082Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M283.297 34.9082H274.57V43.6351H283.297V34.9082Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M335.658 43.6351V34.9082H326.931V43.6351H335.658Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M388.021 43.6351V34.9082H379.295V43.6351H388.021Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M440.383 43.6351V34.9082H431.656V43.6351H440.383Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M492.745 34.9082H484.018V43.6351H492.745V34.9082Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M545.106 34.9082H536.379V43.6351H545.106V34.9082Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M597.467 34.9082H588.74V43.6351H597.467V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.829 34.9082H641.102V43.6351H649.829V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M702.19 34.9082H693.463V43.6351H702.19V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M754.553 34.9082H745.826V43.6351H754.553V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 43.6351V34.9082H798.187V43.6351H806.914Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M859.275 43.6351V34.9082H850.548V43.6351H859.275Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M911.637 43.6351V34.9082H902.91V43.6351H911.637Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M963.998 34.9082H955.271V43.6351H963.998V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M21.4887 87.2695H12.7617V95.9965H21.4887V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M73.85 87.2695H65.123V95.9965H73.85V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M126.213 87.2695H117.486V95.9965H126.213V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M178.575 87.2695H169.848V95.9965H178.575V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M230.936 87.2695H222.209V95.9965H230.936V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M283.297 87.2695H274.57V95.9965H283.297V87.2695Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M335.659 87.2695H326.932V95.9965H335.659V87.2695Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M388.02 87.2695H379.293V95.9965H388.02V87.2695Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M440.383 87.2695H431.656V95.9965H440.383V87.2695Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M492.745 87.2695H484.018V95.9965H492.745V87.2695Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M545.105 95.9965V87.2695H536.379V95.9965H545.105Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M597.467 95.9965V87.2695H588.74V95.9965H597.467Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M649.828 95.9965V87.2695H641.101V95.9965H649.828Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M702.19 87.2695H693.463V95.9965H702.19V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M754.553 87.2695H745.826V95.9965H754.553V87.2695Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 87.2695H798.188V95.9965H806.914V87.2695Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M859.276 87.2695H850.549V95.9965H859.276V87.2695Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M911.637 95.9965V87.2695H902.91V95.9965H911.637Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M964 95.9965V87.2695H955.273V95.9965H964Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M21.4887 139.631H12.7617V148.358H21.4887V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M73.85 139.631H65.123V148.358H73.85V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M178.575 139.631H169.848V148.358H178.575V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M230.936 139.631H222.209V148.358H230.936V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M283.297 148.358V139.631H274.57V148.358H283.297Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M335.658 148.358V139.631H326.931V148.358H335.658Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M388.021 148.358V139.631H379.295V148.358H388.021Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M440.383 139.631H431.656V148.358H440.383V139.631Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M492.745 139.631H484.018V148.358H492.745V139.631Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M545.106 139.631H536.379V148.358H545.106V139.631Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M597.467 139.631H588.74V148.358H597.467V139.631Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.829 139.631H641.102V148.358H649.829V139.631Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M702.19 139.631H693.463V148.358H702.19V139.631Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M754.553 148.358V139.631H745.826V148.358H754.553Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 148.358V139.631H798.187V148.358H806.914Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M859.276 139.631H850.549V148.358H859.276V139.631Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M911.637 139.631H902.91V148.358H911.637V139.631Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M963.998 139.631H955.271V148.358H963.998V139.631Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M21.4887 191.993H12.7617V200.72H21.4887V191.993Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M73.85 191.993H65.123V200.72H73.85V191.993Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M126.213 191.993H117.486V200.72H126.213V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M178.575 191.993H169.848V200.72H178.575V191.993Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M230.936 191.993H222.209V200.72H230.936V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M283.297 191.993H274.57V200.72H283.297V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M335.658 200.72V191.993H326.931V200.72H335.658Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M388.021 200.72V191.993H379.295V200.72H388.021Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M440.383 191.993H431.656V200.72H440.383V191.993Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M492.744 200.72V191.993H484.017V200.72H492.744Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M545.106 191.993H536.379V200.72H545.106V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M597.467 200.72V191.993H588.74V200.72H597.467Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.828 200.72V191.993H641.101V200.72H649.828Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M702.191 200.72V191.993H693.464V200.72H702.191Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M754.553 200.72V191.993H745.826V200.72H754.553Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M963.998 191.993H955.271V200.72H963.998V191.993Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M283.297 244.354H274.57V253.081H283.297V244.354Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M335.658 253.081V244.354H326.931V253.081H335.658Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M388.02 244.354H379.293V253.081H388.02V244.354Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M440.383 244.354H431.656V253.081H440.383V244.354Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M492.745 244.354H484.018V253.081H492.745V244.354Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M545.105 253.081V244.354H536.379V253.081H545.105Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M597.467 253.081V244.354H588.74V253.081H597.467Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M649.828 253.081V244.354H641.101V253.081H649.828Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M702.19 244.354H693.463V253.081H702.19V244.354Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M754.553 244.354H745.826V253.081H754.553V244.354Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M806.914 244.354H798.188V253.081H806.914V244.354Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M859.276 244.354H850.549V253.081H859.276V244.354Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M911.637 244.354H902.91V253.081H911.637V244.354Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M963.998 244.354H955.271V253.081H963.998V244.354Z"
                    fill="white"
                    fillOpacity="0.2"
                />
            </g>
            <g clipPath="url(#clip5_3995_4511)">
                <path
                    d="M1016.89 34.9082H1008.17V43.6351H1016.89V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1069.26 34.9082H1060.53V43.6351H1069.26V34.9082Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1121.62 34.9082H1112.89V43.6351H1121.62V34.9082Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1173.98 34.9082H1165.25V43.6351H1173.98V34.9082Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1226.34 34.9082H1217.62V43.6351H1226.34V34.9082Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1278.7 43.6351V34.9082H1269.98V43.6351H1278.7Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1331.06 43.6351V34.9082H1322.34V43.6351H1331.06Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1383.43 43.6351V34.9082H1374.7V43.6351H1383.43Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1435.79 34.9082H1427.06V43.6351H1435.79V34.9082Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1488.15 34.9082H1479.42V43.6351H1488.15V34.9082Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1540.51 34.9082H1531.79V43.6351H1540.51V34.9082Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1592.87 34.9082H1584.15V43.6351H1592.87V34.9082Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1645.23 43.6351V34.9082H1636.51V43.6351H1645.23Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1697.6 43.6351V34.9082H1688.87V43.6351H1697.6Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1016.89 87.2695H1008.17V95.9965H1016.89V87.2695Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1069.26 87.2695H1060.53V95.9965H1069.26V87.2695Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1121.62 87.2695H1112.89V95.9965H1121.62V87.2695Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1173.98 95.9965V87.2695H1165.25V95.9965H1173.98Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1226.34 95.9965V87.2695H1217.61V95.9965H1226.34Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1278.7 95.9965V87.2695H1269.98V95.9965H1278.7Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1331.06 87.2695H1322.34V95.9965H1331.06V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1383.43 87.2695H1374.7V95.9965H1383.43V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1435.79 87.2695H1427.06V95.9965H1435.79V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1488.15 87.2695H1479.42V95.9965H1488.15V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1540.51 87.2695H1531.79V95.9965H1540.51V87.2695Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1592.87 87.2695H1584.15V95.9965H1592.87V87.2695Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1645.23 87.2695H1636.51V95.9965H1645.23V87.2695Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1697.6 87.2695H1688.87V95.9965H1697.6V87.2695Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1069.26 139.631H1060.53V148.358H1069.26V139.631Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1121.62 139.631H1112.89V148.358H1121.62V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1173.98 139.631H1165.25V148.358H1173.98V139.631Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1226.34 139.631H1217.62V148.358H1226.34V139.631Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1278.7 139.631H1269.98V148.358H1278.7V139.631Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1331.06 139.631H1322.34V148.358H1331.06V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1383.43 139.631H1374.7V148.358H1383.43V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1488.15 139.631H1479.42V148.358H1488.15V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1540.51 139.631H1531.79V148.358H1540.51V139.631Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1592.87 148.358V139.631H1584.15V148.358H1592.87Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1645.23 148.358V139.631H1636.51V148.358H1645.23Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1697.6 148.358V139.631H1688.87V148.358H1697.6Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1173.98 191.993H1165.25V200.72H1173.98V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1226.34 191.993H1217.62V200.72H1226.34V191.993Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1278.7 191.993H1269.98V200.72H1278.7V191.993Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1331.06 191.993H1322.34V200.72H1331.06V191.993Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1383.43 191.993H1374.7V200.72H1383.43V191.993Z"
                    fill="white"
                    fillOpacity="0.2"
                />
                <path
                    d="M1435.79 191.993H1427.06V200.72H1435.79V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1488.15 191.993H1479.42V200.72H1488.15V191.993Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1540.51 191.993H1531.79V200.72H1540.51V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1592.87 191.993H1584.15V200.72H1592.87V191.993Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1645.23 200.72V191.993H1636.51V200.72H1645.23Z"
                    fill="white"
                    fillOpacity="0.15"
                />
                <path
                    d="M1697.6 200.72V191.993H1688.87V200.72H1697.6Z"
                    fill="white"
                    fillOpacity="0.1"
                />
                <path
                    d="M1592.87 244.354H1584.15V253.081H1592.87V244.354Z"
                    fill="#A8C5DF"
                    fillOpacity="0.5"
                />
                <path
                    d="M1645.23 253.081V244.354H1636.51V253.081H1645.23Z"
                    fill="white"
                    fillOpacity="0.05"
                />
                <path
                    d="M1697.6 244.354H1688.87V253.081H1697.6V244.354Z"
                    fill="white"
                    fillOpacity="0.2"
                />
            </g>
            <defs>
                <clipPath id="clip0_3995_4511">
                    <rect
                        width="1274.13"
                        height="287.989"
                        fill="white"
                        transform="translate(-312 255.572)"
                    />
                </clipPath>
                <clipPath id="clip1_3995_4511">
                    <rect
                        width="1274.13"
                        height="287.989"
                        fill="white"
                        transform="translate(997.578 255.572)"
                    />
                </clipPath>
                <clipPath id="clip2_3995_4511">
                    <rect
                        width="1274.13"
                        height="287.989"
                        fill="white"
                        transform="translate(-310.135 513.011)"
                    />
                </clipPath>
                <clipPath id="clip3_3995_4511">
                    <rect
                        width="1274.13"
                        height="287.989"
                        fill="white"
                        transform="translate(999.441 513.011)"
                    />
                </clipPath>
                <clipPath id="clip4_3995_4511">
                    <rect
                        width="1274.13"
                        height="287.989"
                        fill="white"
                        transform="translate(-310.135)"
                    />
                </clipPath>
                <clipPath id="clip5_3995_4511">
                    <rect
                        width="1274.13"
                        height="287.989"
                        fill="white"
                        transform="translate(999.441)"
                    />
                </clipPath>
            </defs>
        </Box>
    )
}
