"use client"
import {
    APP_STORE_URL,
    CHROME_EXTENSION_STORE_URL,
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    PLAY_STORE_URL,
} from "@/constants/routes"
import { getTheme } from "@/theme"
import { Box, Button, SxProps, Theme, alpha } from "@mui/material"
import Image from "next/image"
import { CustomIcon } from "../icons/CustomIcon"
import { Section } from "../layout/Section"
import Card from "../surfaces/Card"
import { Title, TitleSecondary } from "../typography/Title"
import animations from "../utility/animations"
import SlideUpAnimation from "../utility/SlideUpAnimation"

const theme = getTheme()

type PlatformCardProps = {
    type: "browser" | "mobile"
}

const platforms = {
    browser: {
        description: "Install the Browser Extension",
        icon: "/svgs/icons/browser-ext-icon.svg",
        buttonUrls: [CHROME_EXTENSION_STORE_URL, FIREFOX_RECALL_EXTENSION_STORE_URL],
        buttonLabels: ["Chrome", "FireFox"],
        buttonIcons: ["/svgs/chrome.svg", "/svgs/firefox.svg"],
    },
    mobile: {
        description: "Download the Mobile App",
        icon: "/svgs/icons/mobile-app-icon.svg",
        buttonUrls: [APP_STORE_URL, PLAY_STORE_URL],
        buttonLabels: ["App Store", "Google Play"],
        buttonIcons: ["/svgs/apple.svg", "/svgs/android.svg"],
    },
}

const PlatformCard = ({ type }: PlatformCardProps) => {
    const platform = platforms[type]
    return (
        <Card sx={styles.platformCard}>
            <CustomIcon
                sx={{ display: { xs: "none", md: "block" } }}
                src={platform.icon}
                fill
                alt={platform.description}
            />
            <TitleSecondary component="h3" sx={{ textAlign: "left" }}>
                {platform.description}{" "}
                {type === "mobile" && (
                    <Box component="span" sx={{ fontSize: "12px" }}>
                        in βeta
                    </Box>
                )}
            </TitleSecondary>
            <Box sx={styles.platformCardButtons}>
                {platform.buttonLabels.map((_, index) => {
                    return (
                        <Button
                            key={platform.buttonLabels[index]}
                            sx={styles.platformCardButton}
                            href={platform.buttonUrls[index]}
                            target="_blank"
                            aria-label={`Install ${platform.buttonLabels[index]} link button`}
                            startIcon={
                                <Image
                                    width={25}
                                    height={25}
                                    src={platform.buttonIcons[index]}
                                    alt={`${platform.buttonLabels[index]} icon`}
                                />
                            }
                        >
                            {platform.buttonLabels[index]}
                        </Button>
                    )
                })}
            </Box>
        </Card>
    )
}

export default function Platforms() {
    return (
        <Section sx={{ position: "relative", mt: 6 }}>
            <SlideUpAnimation>
                <Card sx={styles.cardContainer}>
                    <Title sx={{ pt: { xs: 0, md: 1 }, pb: 4 }} component="h2" hasBlueGradient>
                        Cross-Platform Accessibility
                    </Title>
                    <Box sx={styles.content}>
                        <PlatformCard type="browser" />
                        <PlatformCard type="mobile" />
                    </Box>
                </Card>
                <Box sx={styles.bgRight}>
                    <Image
                        fill
                        src="/images/backgrounds/platform-glow-bg.webp"
                        alt="Cross-platform background glow"
                    />
                </Box>
                <Box sx={styles.bgLeft}>
                    <Image
                        fill
                        src="/images/backgrounds/platform-glow-bg.webp"
                        alt="Cross-platform background glow"
                        style={{
                            transform: "rotate(-90deg)",
                        }}
                    />
                </Box>
            </SlideUpAnimation>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        my: { xs: 2, lg: 8 },
        position: "relative",
        p: 4,
    },
    content: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: 5,
        width: "100%",
    },
    platformCard: {
        background: "none",
        border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
        boxShadow: "none",
        alignItems: "start",
        gap: 3,
        py: 5,
        px: 4,
        maxWidth: "400px",
    },
    platformCardButton: {
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: "7px",
        px: 2,
        py: 1,
        color: theme.palette.text.secondary,
        ":hover": {
            background: alpha(theme.palette.common.white, 0.1),
        },
    },
    platformCardButtons: {
        display: "flex",
        gap: 2,
        flexDirection: { xs: "column", md: "row" },
    },
    cardContainer: {
        px: { xs: 2, md: 8 },
        background: theme.palette.background.paper,
        py: { xs: 4, md: 8 },
        maxWidth: "1000px",
        width: { xs: "fit-content", md: "100%" },
        mx: "auto",
    },
    bgRight: {
        position: "absolute",
        top: "-55%",
        right: "-35%",
        aspectRatio: 1.16,
        width: "80%",
        zIndex: -2,
        display: { xs: "none", lg: "block" },
        animation: animations.pulsate,
    },
    bgLeft: {
        position: "absolute",
        bottom: "-10%",
        left: "-23%",
        aspectRatio: 1.16,
        width: "80%",
        zIndex: -2,
        display: { xs: "none", lg: "block" },
        animation: animations.pulsate,
    },
}
