"use client"

import { ROUTES } from "@/constants/routes"
import { Box, Grid, SxProps, Theme, Typography, alpha } from "@mui/material"
import Image from "next/image"
import { Section } from "../layout/Section"
import Card from "../surfaces/Card"
import { Title, TitleSecondary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"
import { CustomIcon } from "../icons/CustomIcon"
import { ExternalLink } from "../links/ExternalLink"
import animations from "../utility/animations"

const statements = [
    {
        title: "Data ownership",
        image: "/svgs/icons/lock-icon.svg",
        description:
            "Your data is yours and you have full control over it. We do not use your data for any other purpose than providing a service to you.",
    },
    {
        title: "Data portability",
        image: "/svgs/icons/download-icon.svg",
        description:
            "You can export all your data anytime in Markdown format, putting the power of portability and accessibility directly in your hands.",
    },
    {
        title: "Privacy Focused",
        image: "/svgs/icons/storage-icon.svg",
        description:
            "Augmented Browsing is local first, your knowledge base is stored securely in the cloud.",
    },
]

const cardBackgrounds = [
    "/svgs/backgrounds/pvc-card-bg-one.svg",
    "/svgs/backgrounds/pvc-card-bg-two.svg",
    "/svgs/backgrounds/pvc-card-bg-three.svg",
]

const theme = getTheme()

export default function PrivacyStatement() {
    return (
        <Section>
            <SlideUpAnimation sx={styles.container}>
                <Title component="h2" mb={{ xs: 2, lg: 6 }} maxWidth={800} hasBlueGradient>
                    Security And Data Protection
                </Title>
            </SlideUpAnimation>
            <Grid container alignItems="stretch" justifyContent="center" spacing={2}>
                {statements.map((statement, index: number) => (
                    <Grid key={statement.title} container item md={6} lg={4}>
                        <SlideUpAnimation delay={`${0.1 * index}s`}>
                            <Card sx={styles.card}>
                                <CustomIcon src={statement.image} alt={statement.title} fill />
                                <TitleSecondary my={1} component="h3" fontWeight={600}>
                                    {statement.title}
                                </TitleSecondary>
                                <Typography
                                    variant="body1"
                                    minHeight={{ xs: "auto", md: 100 }}
                                    color={theme.palette.secondary.light}
                                >
                                    {statement.description}
                                </Typography>
                                <Box sx={styles.cardGlow}>
                                    <Image
                                        src={cardBackgrounds[index]}
                                        alt="card glow"
                                        fill
                                        priority={false}
                                    />
                                </Box>
                            </Card>
                        </SlideUpAnimation>
                    </Grid>
                ))}
            </Grid>
            <SlideUpAnimation delay="0.4s" sx={{ ...styles.container, mt: { xs: 4, lg: 8 } }}>
                <ExternalLink target="_blank" href={ROUTES.PRIVACY_POLICY}>
                    Read more about how your data is handled in our privacy policy
                </ExternalLink>
            </SlideUpAnimation>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    featuresBackground: {
        position: "absolute",
        width: "210%",
        bottom: "2.5%",
        aspectRatio: 3.23,
        zIndex: -1,
        display: { xs: "none", lg: "block" },
    },
    card: {
        justifyContent: "center",
        background: "",
        border: `2px solid ${alpha(theme.palette.common.white, 0.1)}`,
        boxShadow: "none",
        position: "relative",
        overflow: "hidden",
        gap: 1,
    },
    cardGlow: {
        position: "absolute",
        width: "100%",
        aspectRatio: 1.9,
        top: 0,
        right: 0,
        animation: animations.pulsate
    },
}
