"use client"
import { getTheme } from "@/theme"
import { Box, keyframes, SxProps, Theme, Typography } from "@mui/material"
import Image from "next/image"
import { Fragment } from "react"
import SlideUpAnimation from "./utility/SlideUpAnimation"

interface MediaLogo {
    src: string
    alt: string
    width: number
    height: number
}

const mediaLogos: MediaLogo[] = [
    {
        src: "svgs/trustbadges/nyu.svg",
        alt: "NYU",
        width: 83,
        height: 28.2,
    },
    {
        src: "svgs/trustbadges/standford.svg",
        alt: "Stanford University",
        width: 98.3,
        height: 41.5,
    },
    {
        src: "svgs/trustbadges/bloomberg.svg",
        alt: "Bloomberg",
        width: 114.7,
        height: 21,
    },
    {
        src: "svgs/trustbadges/linkedin.svg",
        alt: "LinkedIn",
        width: 95.8,
        height: 24,
    },
    {
        src: "svgs/trustbadges/harvard.svg",
        alt: "Harvard",
        width: 117,
        height: 30,
    },
]

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

export const TrustBadges = () => {
    return (
        <SlideUpAnimation visible delay="0.2s">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={3}
            >
                <Typography sx={styles.title}>Trusted by 200,000+ nerds at</Typography>
                <Box component="section" sx={styles.logoContainer}>
                    <Box sx={styles.logoTrack}>
                        {[...Array(3)].map((_, i) => (
                            <Fragment key={`set-${i}`}>
                                {mediaLogos.map((logo) => (
                                    <Box
                                        key={`${logo.src}-${i}`}
                                        width={{ xs: logo.width * 0.7, md: logo.width }}
                                        height={{ xs: logo.height * 0.7, md: logo.height }}
                                        sx={styles.logoWrapper}
                                    >
                                        <Image src={logo.src} alt={logo.alt} fill />
                                    </Box>
                                ))}
                            </Fragment>
                        ))}
                    </Box>
                </Box>
            </Box>
        </SlideUpAnimation>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    logos: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        flexWrap: "wrap",
        gap: { xs: 2, md: 10 },
    },
    title: {
        fontWeight: 400,
        fontSize: { xs: 10, md: 13 },
        textTransform: "uppercase",
        letterSpacing: "0.1em",
        color: (theme) => theme.palette.text.secondary,
        opacity: 0.8,
        textAlign: "center",
    },
    logoContainer: {
        width: "100%",
        maxWidth: "650px",
        overflow: "hidden",

        position: "relative",
        "&::before, &::after": {
            content: '""',
            position: "absolute",
            width: "15%",
            height: "100%",
            top: 0,
            zIndex: 1,
            pointerEvents: "none",
        },
        "&::before": {
            left: 0,
            background: (theme) =>
                `linear-gradient(90deg, ${theme.palette.background.default} 0%, transparent 100%)`,
        },
        "&::after": {
            right: 0,
            background: `linear-gradient(270deg, ${theme.palette.background.default} 0%, transparent 100%)`,
            opacity: 1,
            display: "block",
        },
    },
    logoTrack: {
        display: "flex",
        alignItems: "center",
        animation: `${scroll} 16s linear infinite`,
        width: "fit-content",
        gap: { xs: 4, md: 5 },
    },
    logoWrapper: {
        display: "flex",
        alignItems: "center",
        px: 2,
        position: "relative",
    },
}
